import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./plugins/vuex";
import router from "./plugins/router";
import axios from "axios";
import auth from "./api/auth";
import sitesettings from "./api/sitesettings";
import VueSocketIOExt from "vue-socket.io-extended";
import {io} from "socket.io-client";
import Particles from "particles.vue";
import VueSocialSharing from "vue-social-sharing";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

Vue.config.productionTip = false;

if (process.env.NODE_ENV == "development") {
  //axios.defaults.baseURL = "http://137.184.164.189"; // NOTE("Justin"): this is where we connect to the websocket, login, fetch date etc.
  axios.defaults.baseURL = "http://localhost:3000"; // NOTE("Justin"): this is where we connect to the websocket, login, fetch date etc.
  //axios.defaults.baseURL = "https://lavendaze.ca";
} else {
  axios.defaults.baseURL = "https://lavendaze.ca";
}

var socket = io(axios.defaults.baseURL, {
  autoConnect: false, // NOTE("Justin"): connecting later on after correct auth has been setup
});

Vue.use(VueSocketIOExt, socket, {store});
Vue.use(VueSocialSharing);
Vue.use(Particles);
Vue.use(VueViewer);

new Vue({
  vuetify,
  router,
  store: store,
  render: (h) => h(App),
  data: {
    loggedIn: false,
    user: null,
    isAdmin: false,
    jwt_token: "",
    mapbox_public_token: null,
    settings: null,
    customerView: false,

    usingGeolocation: false,
    geolocationWatchId: null,
    currentCoords: null,
    lazySrc: "https://picsum.photos/id/11/100/60",
  },
  mounted() {
    var preLoggedInUser = window.localStorage.getItem("user");
    var preLoggedInToken = window.localStorage.getItem("jwt_token");
    if (preLoggedInUser && preLoggedInToken && preLoggedInToken != "null") {
      // NOTE("Justin"): Doing this first because the auth.refreshToken endpoint uses the current jwt_token to see if it can refresh it
      this.setLoggedInWithUser(preLoggedInUser, preLoggedInToken, false);

      auth.refreshToken((response) => {
        if (response.data && response.data.success) {
          this.mapbox_public_token = response.data.mapbox_public_token;
          this.setLoggedInWithUser(response.data.user, response.data.token);
          if (this.isAdmin) {
            this.customerView = window.localStorage.getItem("customerView") === "true";
          }
        } else {
          //alert(response.message);
          this.mapbox_public_token = null;
          this.setLoggedInWithUser(null, null, false);
        }
      });
    }

    sitesettings.readCurrent((resp) => {
      this.settings = resp.success ? resp.data : null;
    });
  },
  watch: {
    customerView(newVal, oldVal) {
      window.localStorage.setItem("customerView", newVal);
    },
  },
  methods: {
    Logout() {
      this.setLoggedInWithUser(null, null, false);
      this.DisconnectWebsocket();
    },
    setLoggedInWithUser(user, jwt_token, connectSocket = true) {
      this.loggedIn = jwt_token ? true : false;
      this.user = user;
      this.isAdmin = user ? user.role === "Admin" : false;
      var newToken = this.jwt_token != jwt_token;
      this.jwt_token = jwt_token;
      var auth = "Bearer " + jwt_token;

      if (jwt_token && newToken) {
        axios.defaults.headers.common["Authorization"] = auth;
        window.localStorage.setItem("user", user);
        window.localStorage.setItem("jwt_token", jwt_token);
        //this.FetchGeolocation();
      } else {
        delete axios.defaults.headers.common["Authorization"];
        //this.CancelGeolocation();
        // NOTE("Justin"): (NOT REMOVING THESE) if the server is reloading during this point we lose the cached token
        //window.localStorage.removeItem('user');
        //window.localStorage.removeItem('jwt_token');
      }

      if (connectSocket) {
        this.ConnectWebSocket();
      }
    },
    SettingsChanged() {
      sitesettings.update(this.settings, (resp) => {
        this.settings = resp.success ? resp.data : null;
      });
    },
    DisconnectWebsocket() {
      socket.disconnect();
    },
    ConnectWebSocket() {
      socket.auth = {jwt_token: this.jwt_token};
      socket.connect();
    },
    CancelGeolocation() {
      if (this.usingGeolocation) {
        this.usingGeolocation = false;
        navigator.geolocation.clearWatch(this.geolocationWatchId);
        this.geolocationWatchId = null;
      }
    },
    FetchGeolocation() {
      // NOTE("Justin"): asking for location permission if it doesn't have it //https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API
      if (navigator.geolocation) {
        if (this.geolocationWatchId == null) {
          this.usingGeolocation = true;

          // navigator.geolocation.getCurrentPosition((pos) => {
          //   this.currentCoords = pos.coords;
          //   console.log(`FETCHED LONGITUDE:${this.currentCoords.longitude}`);
          //   console.log(`FETCHED LATITUDE:${this.currentCoords.latitude}`);
          // }, (err) => {
          //   console.error(`GEOLOCATION ERROR(${err.code}): ${err.message}`);
          // });

          this.geolocationWatchId = navigator.geolocation.watchPosition(
            (pos) => {
              this.currentCoords = pos.coords;
              //https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates
              //console.log(`FETCHED LONGITUDE:${this.currentCoords.longitude}`);
              //console.log(`FETCHED LATITUDE:${this.currentCoords.latitude}`);
              //console.log(`FETCHED HEADING:${this.currentCoords.heading}`); // Direction: if speed is 0 dir is NaN
              //console.log(`FETCHED SPEED:${this.currentCoords.speed}`);

              if (socket.connected) {
                socket.emit("location_update", {
                  longitude: this.currentCoords.longitude,
                  latitude: this.currentCoords.latitude,
                  heading: this.currentCoords.heading,
                  speed: this.currentCoords.speed,
                });
              }
            },
            (err) => {
              console.error(`GEOLOCATION ERROR(${err.code}): ${err.message}`);
            }
          );
        }
      } else {
        alert("Geolocation not supported for you");
      }
    },
    CurrentCoords() {
      if (!this.usingGeolocation || !this.currentCoords) {
        return [-81.2845695, 42.9651865];
      }
      return [this.currentCoords.longitude, this.currentCoords.latitude];
    },
  },
  computed: {
    CanShowAdminOptions() {
      return this.isAdmin && !this.customerView;
    },
  },
  sockets: {
    connect() {
      console.log("socket connected!");
    },
    disconnect() {
      console.log("socket disconnected");
    },
    connect_error(err) {
      console.log(err instanceof Error); // true
      console.log(err.message); // not authorized
      console.log(err.data); // { content: "Please retry later" }
    },
    // new_job(data) {
    //   console.log('new_job:')
    //   console.log(JSON.stringify(data, null, 2));
    // },
  },
}).$mount("#app");
