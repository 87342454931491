import axios from "axios";

export default {
  readAll(callBack) {
    axios
      .post("/customermsg/readAll")
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },

  create(customermsg, callBack) {
    axios
      .post("/customermsg/create", customermsg)
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },

  update(customermsg, callBack) {
    axios
      .post("/customermsg/update", customermsg)
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },

  delete(id, callBack) {
    axios
      .delete("/customermsg/delete", {data: {id: id}})
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },

  handle(id, handled, callBack) {
    axios
      .put("/customermsg/handle", {id: id, handled: handled})
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },
};
