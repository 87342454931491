import axios from "axios";
import moment, {invalid} from "moment";

export default {
  // NOTE("Justin"): strips null/undefined properties off an object
  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  },

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  },

  getMeta(metaName) {
    const metas = document.getElementsByTagName("meta");

    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute("name") === metaName) {
        return metas[i].getAttribute("content");
      }
    }

    return "";
  },

  // NOTE ("Justin"): callback returning the date object
  getDate(callBack) {
    axios
      .get("/api/getDate")
      .then((response) => {
        callBack(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  NiceTime(datetime) {
    // Format: 10:23 AM  // https://momentjs.com/docs/#/parsing/
    var ret = "";
    if (datetime != null) {
      var m = moment(datetime);
      ret = m.format("h:mma");
    }
    return ret;
  },

  ShortDateTime(datetime) {
    // Format:  Sun, Aug 9, 2020 10:23 AM  // https://momentjs.com/docs/#/parsing/
    var ret = "";
    if (datetime != null) {
      var m = moment(datetime);
      ret = m.format("M/D/YYYY h:mma");
    }
    return ret;
  },

  ShortDateNoTime(datetime) {
    // Format:   Mon, Feb, 1st, 2021  // https://momentjs.com/docs/#/parsing/
    var ret = "";
    if (datetime) {
      var m = moment(datetime);
      ret = m.format("ddd, MMM, Do, YYYY");
    }
    return ret;
  },

  LongDate(datetime) {
    // Format:  Sun, Aug 9, 2020 10:23 AM  // https://momentjs.com/docs/#/parsing/
    var ret = "";
    if (datetime != null) {
      var m = moment(datetime);
      ret = m.format("llll");
    }
    return ret;
  },

  LongTimeSlotDate(time) {
    // Format:  Sun, Aug 9, 2020 10:23 AM  // https://momentjs.com/docs/#/parsing/
    var ret = "";
    if (time != null) {
      var m = moment(time);
      ret = m.format("llll");
    }
    return ret;
  },

  TimeSlotDate(timeSlot) {
    // Format:   Sun, Aug 9, 2020  // https://momentjs.com/docs/#/parsing/
    var ret = "";
    if (timeSlot != null) {
      var m = moment(timeSlot.startTimePre);
      ret = m.format("LL");
    }
    return ret;
  },

  TimeSlotTime(timeSlot) {
    // Format:   // 10:25 AM // https://momentjs.com/docs/#/parsing/
    var ret = "";
    if (timeSlot != null) {
      var m = moment(timeSlot.startTimePre);
      ret = m.format("LT");
    }
    return ret;
  },

  TimeSlotTimeMinusMins(timeSlot, mins) {
    // Format:   // 10:25 AM // https://momentjs.com/docs/#/parsing/
    var ret = "";
    if (timeSlot != null) {
      var m = moment(timeSlot.startTimePre).add(mins, "m");
      ret = m.format("LT");
    }
    return ret;
  },

  GetPlatformString(value) {
    var ret = "";
    switch (value) {
      case 0:
        ret = "Website";
        break;
      case 1:
        ret = "Facebook";
        break;
      case 2:
        ret = "Kijiji";
        break;
      case 3:
        ret = "Google";
        break;
      default:
        ret = "??";
        break;
    }
    return ret;
  },

  GetClampedText(text, length, clamp = null) {
    if (text == null) {
      return "NULL";
    }
    clamp = clamp || "...";
    return text.length > length ? text.slice(0, length) + clamp : text;
  },

  getRecaptchaPrivateKey(callBack) {
    axios
      .get("/api/getRecaptchaPrivateKey")
      .then((response) => {
        callBack(response.data);
      })
      .catch((err) => {
        console.log(err);
        callBack(err);
      });
  },

  verifyCaptcha(request, callBack) {
    axios
      .post("/verifyCaptcha", request)
      .then((response) => {
        callBack(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  getPosition(el) {
    var xPos = 0;
    var yPos = 0;

    if (el != null) {
      if (el.tagName == "BODY") {
        // deal with browser quirks with body/window/document and page scroll
        var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
        var yScroll = el.scrollTop || document.documentElement.scrollTop;

        xPos += el.offsetLeft - xScroll + el.clientLeft;
        yPos += el.offsetTop - yScroll + el.clientTop;
      } else {
        // for all other non-BODY elements
        xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPos += el.offsetTop - el.scrollTop + el.clientTop;
      }

      el = el.offsetParent;
    }
    return {
      x: xPos,
      y: yPos,
    };
  },

  ProcessPhoneNumber(phoneNumber) {
    if (phoneNumber == null || "") {
      return "";
    }

    var phone = this.MinimalPhoneNumber(phoneNumber);
    var tmp = phone.split("");
    if (phone.length == 10) {
      tmp.splice(tmp.length - 7, 0, "-");
      tmp.splice(tmp.length - 4, 0, "-");
      phone = tmp.join("");
    } else if (phone.length == 7) {
      tmp.splice(tmp.length - 4, 0, "-");
      phone = tmp.join("");
    }
    return phone;
  },

  MinimalPhoneNumber(phoneNumber) {
    var phone = phoneNumber.replace(/\ |\+|\-/gi, function (x) {
      return "";
    });
    if (phone.length > 10) {
      var diff = phone.length - 10;
      var tmp = phone.split("");
      tmp.splice(0, diff);
      phone = tmp.join("");
    }
    return phone;
  },

  GetQuantityRateString(booking) {
    var ret = "Quantity: ";
    if (booking != null) {
      ret += "x" + booking.quantity + " - ";
      ret += "Rate: $" + booking.category.price_max + " pp";
    }
    return ret;
  },

  GetShopItemCost(item, quantity) {
    var ret = "Price: $";
    if (item) {
      if (item.costMin == item.costMax) {
        ret += (quantity * item.costMin).toFixed(2);
      } else {
        ret += (quantity * item.costMin).toFixed(2);
        ret += " - $";
        ret += (quantity * item.costMax).toFixed(2);
      }
    }
    return ret;
  },

  GetSubTotal(booking) {
    var ret = "$";
    if (booking != null) {
      ret += (booking.quantity * booking.category.price_max).toFixed(2);
    }
    return ret;
  },

  GetTaxAmount() {
    return 0.13;
  },

  GetTaxTotalString(booking, promoDef) {
    var ret = "HST: ";
    if (booking != null && booking.category != null) {
      var ppp = this.ApplyPromoDef(booking.category.price_max, promoDef);
      var sub = booking.quantity * ppp;
      var hst = this.GetTaxAmount();
      ret += "$" + (sub * hst).toFixed(2);
    }
    return ret;
  },

  GetTotalString(booking, promoDef) {
    var ret = "Total: ";
    if (booking != null && booking.category != null) {
      var ppp = this.ApplyPromoDef(booking.category.price_max, promoDef);
      var sub = booking.quantity * ppp;
      var hst = this.GetTaxAmount();
      var tot = (sub * hst + sub).toFixed(2);
      ret += "$" + tot;
    }
    return ret;
  },

  GetSubTotalString(booking) {
    var ret = "Subtotal: ";
    if (booking != null && booking.category != null) {
      ret += this.GetSubTotal(booking);
    }
    return ret;
  },

  ApplyPromoDef(price, promoDef) {
    if (promoDef) {
      if (promoDef.dollars > 0) {
        price -= promoDef.dollars;
      } else {
        price -= price * (promoDef.percent / 100.0);
      }
    }
    return price;
  },

  GetSubTotalPromoString(booking, promoDef) {
    var ret = "Discount: ";
    if (booking && booking.category && promoDef) {
      var ppp = this.ApplyPromoDef(booking.category.price_max, promoDef);
      var subtotal = booking.quantity * booking.category.price_max;
      var subtotalFinal = booking.quantity * ppp;
      ret += "$" + (subtotal - subtotalFinal).toFixed(2);
    }
    return ret;
  },

  Download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  },

  CSVToArray(strData, strDelimiter) {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ",";

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
      // Delimiters.
      "(\\" +
        strDelimiter +
        "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        // Standard fields.
        '([^"\\' +
        strDelimiter +
        "\\r\\n]*))",
      "gi"
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
      // Get the delimiter that was found.
      var strMatchedDelimiter = arrMatches[1];

      // Check to see if the given delimiter has a length
      // (is not the start of string) and if it matches
      // field delimiter. If id does not, then we know
      // that this delimiter is a row delimiter.
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        // Since we have reached a new row of data,
        // add an empty row to our data array.
        arrData.push([]);
      }

      var strMatchedValue;

      // Now that we have our delimiter out of the way,
      // let's check to see which kind of value we
      // captured (quoted or unquoted).
      if (arrMatches[2]) {
        // We found a quoted value. When we capture
        // this value, unescape any double quotes.
        strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3];
      }

      // Now that we have our value string, let's add
      // it to the data array.
      arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
  },
};
