<template>
  <div class="text-center">
    <v-card class="pa-8" dark>
      <div class="d-flex align-start">
        <v-btn
          v-if="$root.isAdmin"
          color="primary"
          dark
          class="mb-2"
          @click="dialog = !dialog"
        >
          New Job
        </v-btn>
      </div>
      <v-dialog v-model="dialog" max-width="700px">
        <v-card @keydown.enter.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Job Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    :items="priorities"
                    v-model="editedItem.priority"
                    label="Priority"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    :items="jobTypes"
                    v-model="editedItem.type"
                    label="Job Type"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <CmpLocationPicker
                    :currentDestination="editedItem.destination"
                    v-model="editedItem.destination"
                    ref="locationPicker"
                  ></CmpLocationPicker>
                  <!-- <v-text-field
                    v-model="editedItem.destination"
                    label="Destination:TODO"
                  ></v-text-field> -->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this job?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-iterator
        :items="jobs"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
      >
        <template v-slot:header>
          <v-toolbar dark color="blue darken-3" class="mb-1">
            <v-text-field
              v-model="search"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-spacer></v-spacer>
              <v-select
                v-model="sortBy"
                flat
                solo-inverted
                hide-details
                :items="keys"
                prepend-inner-icon="mdi-magnify"
                label="Sort by"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn-toggle v-model="sortDesc" mandatory>
                <v-btn large depressed color="blue" :value="false">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn large depressed color="blue" :value="true">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.name"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card>
                <v-card-title class="subheading font-weight-bold">
                  {{ item.name ? item.name : "Job " + item.id }}
                  <v-chip
                    v-if="item.state == 'NEW'"
                    class="ma-2"
                    color="success"
                    outlined
                  >
                    <v-icon class="mr-2">mdi-car</v-icon>
                    NEW
                  </v-chip>
                  <v-chip
                    v-if="item.state == 'TAKEN'"
                    class="ma-2"
                    color="orange"
                    outlined
                  >
                    <v-icon class="mr-2">mdi-car-connected</v-icon>
                    TAKEN
                  </v-chip>
                  <v-chip
                    v-if="item.state == 'FINISHED'"
                    class="ma-2"
                    color="blue"
                    outlined
                  >
                    <v-icon class="mr-2">mdi-map-marker-check</v-icon>
                    FINISHED
                  </v-chip>
                  <v-spacer> </v-spacer>

                  <div v-if="$root.isAdmin">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </div>
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item
                    v-for="(key, index) in filteredKeys"
                    :key="index"
                  >
                    <v-list-item-content
                      :class="{ 'blue--text': sortBy === key }"
                    >
                      {{ key }}:
                    </v-list-item-content>
                    <v-list-item-content
                      class="align-end"
                      :class="{ 'blue--text': sortBy === key }"
                    >
                      {{
                        key == "destination" ? item[key].place_name : item[key]
                      }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="item.state == 'NEW'"
                    color="primary"
                    text
                    @click="StartJob(item)"
                  >
                    Take!
                  </v-btn>
                  <div v-if="$root.user.id == item.runnerId || $root.isAdmin">
                    <v-btn
                      v-if="item.state == 'TAKEN'"
                      color="green"
                      text
                      @click="FinishJob(item)"
                    >
                      Finish!
                    </v-btn>
                    <v-btn
                      v-if="item.state == 'TAKEN'"
                      color="red"
                      text
                      @click="CancelJob(item)"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      v-if="item.state == 'FINISHED' && $root.isAdmin"
                      color="primary"
                      text
                      @click="RemoveFinished(item)"
                    >
                      Remove Finished
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-row class="mt-2" align="center" justify="center">
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span class="mr-4 grey--text">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import jobs from "../api/jobs";
import utils from "../api/utils";
import CmpLocationPicker from "../components/CmpLocationPicker.vue";

export default {
  components: {
    CmpLocationPicker,
  },
  created() {
    this.editedItem = Object.assign({}, this.defaultItem);
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: 0,
        name: "",
        type: "",
        priority: 5,
        destination: null,
        sourceId: "",
        sourceType: "",
      },
      defaultItem: {
        id: 0,
        name: "New Job Name",
        type: "Pickup",
        priority: 5,
        destination: null,
        sourceId: "99",
        sourceType: "Web",
      },
      priorities: [1, 2, 3, 4, 5],
      jobTypes: ["Pickup", "Dropoff", "Custom"],
      itemsPerPageArray: [4, 8, 12, 24, 48, 64],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: "priority",
      keys: [
        "type",
        "priority",
        "state",
        "id",
        "destination",
        "sourceId",
        "sourceType",
        "runnerId",
      ],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.jobs.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Job" : "Edit Job";
    },
    //can also use local state in here too as stated in vuex  https://vuex.vuejs.org/guide/state.html#the-mapstate-helper
    ...mapState({
      jobs: (state) => state.jobs.jobs,
      jobCount: (state) => state.jobs.jobs.length,
    }),
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.jobs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.locationPicker.TrySetting();
      });
    },

    deleteItem(item) {
      this.editedIndex = this.jobs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      jobs.delete(this.editedItem.id, (resp) => {});
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      var job = this.editedItem;
      if (this.editedIndex > -1) {
        delete job["createdAt"];
        delete job["updatedAt"];
        job = utils.clean(job);
        jobs.update(job, (resp) => {
          if (!resp.success) {
            alert(resp.error.message);
          }
        });
      } else {
        delete job["id"];
        job.sourceId = String(this.$root.user.id);
        jobs.create(job, (resp) => {
          if (!resp.success) {
            alert(resp.error.message);
          }
        });
      }
      this.close();
    },

    StartJob(job) {
      jobs.start(job.id, (resp) => {
        if (!resp.success) {
          alert(resp.error.message);
        }
      });
    },
    FinishJob(job) {
      if (confirm("Are you sure you are finished?")) {
        jobs.finish(job.id, (resp) => {
          if (!resp.success) {
            alert(resp.error.message);
          }
        });
      }
    },
    CancelJob(job) {
      if (confirm("Are you sure you want to cancel?")) {
        jobs.cancel(job.id, (resp) => {
          if (!resp.success) {
            alert(resp.error.message);
          }
        });
      }
    },
    RemoveFinished(job) {
      if (confirm("Are you sure you want to remove this?")) {
        jobs.delete(job.id, (resp) => {});
      }
    },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style>
</style>