<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet" >
      <v-list class="pb-0 pt-0" dark color="primary" v-for="item,i in networks" :key="i">
        <ShareNetwork v-if="item.type != 'Clipboard'" :network="item.type" :url="url" :title="title" :description="description" :quote="quote" :hashtags="hashtags">
        <v-list-item @click="sheet = false">
         <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </ShareNetwork>
        <v-list-item v-else @click="ClickedClipboard">
         <v-list-item-icon>
              <v-icon>mdi-clipboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Clipboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<style>
  .share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
  }
</style>

<script >
export default {
  data() {
    return {
      sheet : false,
      data: null,

      url:'',
      title: '',
      description: '',
      quote: '',
      hashtags: 'lavendaze, lavendaze shop',

      networks: [
        {type:"facebook", text: "Facebook", icon:"mdi-facebook"},
        {type:"Messenger", text: "Messenger", icon:"mdi-facebook-messenger"},
        {type:"Email", text: "Email", icon:"mdi-email"},
        {type:"SMS", text: "SMS", icon:"mdi-cellphone-text"},
        {type:"Clipboard", text: "Clipboard", icon:"mdi-cellphone-text"},
      ]
    }
  },
  methods: {
    Open(data)
    {
      this.data = data;
      this.sheet = true;
      var innerdata = data.data;
      if(data.type == "blog")
      {
          this.url = window.location.origin + `/#/blog/id/${innerdata._id}`
          this.title = innerdata.title;
          this.description = innerdata.description;
          this.quote = innerdata.subtitle;
      }
      else if(data.type == "shopItem")
      {
          this.url = window.location.href;
          this.title = innerdata.title;
          this.description = innerdata.description;
          this.quote = innerdata.subtitle;
      }
    },
    ClickedClipboard()
    {
        this.sheet = false;
        var text = `${this.url}`;
          navigator.clipboard.writeText(text).then(function() {
            //console.log('Async: Copying to clipboard was successful!');
          }, function(err) {
            //console.error('Async: Could not copy text: ', err);
          });
    }
  }
}
</script>
