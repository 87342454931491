<template>
  <div>
  <v-footer
    app
    absolute
    padless
  >
    <v-card
      flat
      tile
      style="width:100%; background-color:#593277;"
      class="white--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="icon,i in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.url"
          target="_blank"
          icon
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>


      <v-card-text class="text-h5 white--text pt-0">
        <v-icon color="green" style="-webkit-transform: scaleX(-1); transform: scaleX(-1);">mdi-leaf</v-icon> Soothe · Renew · Uplift <v-icon color="green">mdi-leaf</v-icon>
      </v-card-text>

      <v-card-text class="pt-0">
             <v-btn rounded color="secondary" @click="$emit('show-contact-us')" >
            Contact Us
            <v-icon class="ml-1">mdi-message</v-icon>
          </v-btn>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Lavendaze</strong>
      </v-card-text>
    </v-card>
  </v-footer>
  </div>
</template>

<script >
export default {
  data() {
    return {
      drawer : false,
      icons : [
        {icon:'mdi-facebook', url:"https://www.facebook.com/lavendaze"},
        {icon:'mdi-instagram', url:"https://www.instagram.com/lavendazeshop"},
    ],
    }
  }
  
}
</script>
