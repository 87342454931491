<template>
  <div class="text-center">
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showing" width="500" :click:outside="Close" dark>
      <v-card dark color="primary">
        <v-card-title class="text-h5"> Contact Us </v-card-title>

        <v-form ref="form">

        

        <v-card-text>
          <v-text-field
            v-model="email"
            :rules="rules.emailRules"
            autocomplete="on"
            label="Enter Email"
            name="email"
          ></v-text-field>
        </v-card-text>
         <v-card-text class="mt-n5">
          <v-text-field
            v-model="phone"
            autocomplete="on"
            label="Enter Phone (Optional)"
            name="phone"
          ></v-text-field>
        </v-card-text>
        <v-card-text class="pb-0">
          <v-textarea
          light
          solo
          v-model="message"
          label="Enter Message"
        ></v-textarea>
        </v-card-text>

        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" outlined text @click="ClickedSend">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import rules from "../api/rules";
import customerMsg from "../api/customerMsg";

export default {
  props: {},
  data() {
    return {
      showing: false,
      loadingOverlay: false,
      rules: rules,
      email: "",
      phone: "",
      message: "",
    };
  },
  methods: {
    Open() {
      this.showing = true;
    },
    Close() {
      this.showing = false;
    },
    ClickedSend() {

      var valid = this.$refs.form.validate();

      if(!valid)
      {
        return;
      }

      var request = {
        type: "ContactUs",
        email: this.email,
        phone: this.phone,
        message: this.message,
      };

      this.loadingOverlay = true;
      customerMsg.create(request, (loginResponse) => {
        this.loadingOverlay = false;
        if (loginResponse.success) {
          alert("Thank you, we will be in contact with you shortly.");
          this.Close();
        } else {
          alert(loginResponse.error.message);
        }
      });
    },
  },
};
</script>