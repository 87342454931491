<template>
  <div class="text-center">
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showing" width="500" :click:outside="Close" dark>
      <v-card dark color="primary">
        <v-card-title class="text-h5"> Login </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="email"
            :rules="rules.emailRules"
            autocomplete="on"
            label="Enter Email"
            name="email"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="rules.passwordRules"
            :type="showPassword ? 'text' : 'password'"
            class="pb-0"
            name="password"
            autocomplete="on"
            label="Password"
            hint="At least 8 characters"
            counter
            @keydown.enter="ClickedLogin"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white" outlined text @click="ClickedLogin"> Login! </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import rules from "../api/rules";
import auth from "../api/auth";

export default {
  props: {},
  data() {
    return {
      showing: false,
      loadingOverlay: false,
      rules: rules,
      email: "",
      password: "",
      showPassword: false,
    };
  },
  methods: {
    Open() {
      this.showing = true;
    },
    Close() {
      this.showing = false;
    },
    ClickedLogin() {
      //var request = new FormData();
      //request.append("email", this.email);
      //request.append("password", this.password);
      //request.append("remember", this.remember);

      var request = {
        email: this.email,
        password: this.password,
      };

      this.loadingOverlay = true;
      auth.login(request, (loginResponse) => {
        this.loadingOverlay = false;
        if (loginResponse.data && loginResponse.data.success) {
          //alert(loginResponse.data.message);
          this.Close();
          this.$root.setLoggedInWithUser(
            loginResponse.data.user,
            loginResponse.data.token
          );
          this.$root.mapbox_public_token =
            loginResponse.data.mapbox_public_token;
        } else {
          alert(loginResponse.message);
        }
      });
    },
  },
};
</script>