import axios from 'axios'

export default {

  register(user, callBack) {
    //console.log("REGISTERING USER: " + JSON.stringify(user));
    axios.post("/register", user).then(response => {
      callBack(response);
    }).catch(error => {
      callBack({ success: false, error: error });
    });
  },

  login(user, callBack) {
    axios.post("/user/login", user).then(response => {
      callBack(response);
    }).catch(error => {
      callBack({ success: false, error: error, message: error.message });
    });
  },

  refreshToken(callBack) {
    axios.post("/user/refreshToken", { message: "requesting new token." }).then(response => {
      callBack(response);
    }).catch(error => {
      callBack({ success: false, error: error, message: error.message });
    });
  },

  logout(callBack) {
    axios.get("/logout").then(response => {
      callBack(response);
    }).catch(error => {
      callBack({ success: false, error: error });
    });
  },

  sendVerificationEmail(callBack) {
    axios.get("/sendVerificationEmail").then(response => {
      callBack(response);
    }).catch(error => {
      callBack({ success: false, error: error });
    });
  },

  isLoggedIn(request, callBack) {
    axios.post("/isLoggedIn", request).then(response => {
      callBack(response);
    }).catch(error => {
      callBack({ success: false, error: error });
    });
  },

  forgotPass(request, callBack) {
    axios.post("/sendPasswordReset", request).then(response => {
      callBack(response);
    }).catch(error => {
      callBack({ success: false, error: error });
    });
  },

  setNewPasswordFromProfile(request, callBack) {
    axios.post("/setNewPasswordFromProfile", request).then(response => {
      callBack(response.data);
    }).catch(error => {
      callBack({ success: false, error: error });
    });
  },

  setNewPasswordFromToken(request, callBack) {
    axios.post("/setNewPasswordFromToken", request).then(response => {
      callBack(response.data);
    }).catch(error => {
      callBack({ success: false, error: error });
    });
  },

  getPasswordResetSessionData(callBack) {
    axios.get("/getPasswordResetSessionData").then(response => {
      callBack(response.data);
    }).catch(error => {
      callBack({ success: false, error: error });
    });
  },

}
