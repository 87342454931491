import axios from 'axios'

export default {

    create(job, callBack) {
        axios.post("/job/create", job).then(response => {
            if (response.data) { callBack(response.data); }
            else { callBack({ success: false, error: new Error("no data obj") }); }
        }).catch(error => {
            callBack({ success: false, error: error });
        });
    },

    // JSON OBJ REQUEST { id: 2, ...}  
    update(job, callBack) {
        axios.post("/job/update", job).then(response => {
            if (response.data) { callBack(response.data); }
            else { callBack({ success: false, error: new Error("no data obj") }); }
        }).catch(error => {
            callBack({ success: false, error: error });
        });
    },

    delete(id, callBack) {
        axios.delete("/job/delete", { data: { id: id } }).then(response => {
            if (response.data) { callBack(response.data); }
            else { callBack({ success: false, error: new Error("no data obj") }); }
        }).catch(error => {
            callBack({ success: false, error: error });
        });
    },

    start(id, callBack) {
        axios.post("/job/start", { id: id }).then(response => {
            if (response.data) { callBack(response.data); }
            else { callBack({ success: false, error: new Error("no data obj") }); }
        }).catch(error => {
            callBack({ success: false, error: error });
        });
    },

    finish(id, callBack) {
        axios.post("/job/finish", { id: id }).then(response => {
            if (response.data) { callBack(response.data); }
            else { callBack({ success: false, error: new Error("no data obj") }); }
        }).catch(error => {
            callBack({ success: false, error: error });
        });
    },

    cancel(id, callBack) {
        axios.post("/job/cancel", { id: id }).then(response => {
            if (response.data) { callBack(response.data); }
            else { callBack({ success: false, error: new Error("no data obj") }); }
        }).catch(error => {
            callBack({ success: false, error: error });
        });
    },
}