import axios from "axios";

export default {
  get_url(callBack) {
    axios
      .get("/instagram_auth/link_url")
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },
  feed(callBack) {
    axios
      .get("/instagram_auth/feed")
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },
};
