<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    no-filter
    return-object
    item-text="place_name"
    label="Select a location"
    ref="inputRef"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          {{ "Search for your" }}
          <strong>Location</strong>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <v-icon left> mdi-map-marker </v-icon>
      <span v-text="item.place_name"></span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="primary"
        class="text-h5 font-weight-light white--text"
      >
        <v-icon color="white"> mdi-map-marker </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.place_name"></v-list-item-title>
        <!-- <v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle> -->
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import map from "../api/map";
export default {
  props: {
    currentDestination: Object,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      model: null,
      search: null,
    };
  },
  methods: {
    TrySetting() {
      if (this.model == null && this.currentDestination != null) {
        this.items = [this.currentDestination];
        this.model = this.items[0];
      } else {
      }
    },
  },
  watch: {
    model(val) {
      if (val != null) {
        this.$emit("input", val);
      } else {
        // this.items = [this.currentDestination];
        // this.model = this.items[0];
      }
    },
    search(val) {
      // Items have already been loaded
      if (this.isLoading || !val || val == "" || val == " ") {
        return;
      }

      this.isLoading = true;

      // Lazily load input items
      var url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${escape(
        val
      )}.json?country=ca&types=address&access_token=${
        this.$root.mapbox_public_token
      }`;

      fetch(url)
        .then((res) => res.clone().json())
        .then((res) => {
          this.items = res.features;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style>
</style>