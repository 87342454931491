<template>
  <v-app>
    <Particles
            v-if="$root.settings && $root.settings.snowEffect"
            id="tsparticles"
            :particlesInit="particlesInit"
            :particlesLoaded="particlesLoaded"
            :options="SnowOptions"/>
    <CmpHeader @show-contact-us="ShowContactUs"></CmpHeader>
    <CmpContactUs ref="cmpContactUs"></CmpContactUs> 
    <v-main >
      <v-container fluid >
        <router-view id="mainContainer" @show-share="ShowShare"></router-view>
      </v-container>
    </v-main>
    <CmpFooter @show-contact-us="ShowContactUs"></CmpFooter>
    <CmpShare ref="cmpShare"/>
  </v-app>
</template>

<style lang="scss">
</style>

<script>
import CmpHeader from "@/components/CmpHeader.vue";
import CmpFooter from "@/components/CmpFooter.vue";
import CmpContactUs from "@/components/CmpContactUs.vue"
import SnowOptions from "@/plugins/snow.js"
import CmpShare from './components/CmpShare.vue';


export default {
  data: () => ({
    SnowOptions: SnowOptions,
  }),

  methods: {
    particlesInit(){

    },
    particlesLoaded(){

    },
    ShowShare(data)
    {
      this.$refs.cmpShare.Open(data);
    },
    ShowContactUs()
    {
      this.$refs.cmpContactUs.Open();
    }
  },
  components: {
    CmpHeader,
    CmpFooter,
    CmpShare,
    CmpContactUs
  },
};
</script>

