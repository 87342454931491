import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

//https://www.npmjs.com/package/vue-socket.io-extended
// const jobs = {
//   state: {
//     jobs: []
//   },
//   mutations: {
//     SOCKET_ALL_JOBS(state, jobsData) {
//       state.jobs = jobsData;
//     },
//     SOCKET_NEW_JOB(state, jobData) {
//       state.jobs.push(jobData);
//     },
//     SOCKET_DELETE_JOB(state, jobData) {
//       state.jobs.splice(state.jobs.findIndex(job => job.id === jobData.id), 1);
//     },
//     SOCKET_UPDATE_JOB(state, jobData) {
//       state.jobs.splice(state.jobs.findIndex(job => job.id === jobData.id), 1, jobData);
//     }
//   },
//   actions: {
//     // socket_newJob({ dispatch, commit }, jobData) {
//     //   //console.log('new job:');
//     //   //console.log(JSON.stringify(jobData, null, 2));
//     //   //commit('SOCKET_NEW_JOB', jobData);
//     // }
//   },
// };

const users = {
  state: {
    users: [],
    user_locations: [],
  },
  mutations: {
    SOCKET_USER_CONNECTION(state, userData) {
      state.users = userData.users;
    },
    SOCKET_USER_DISCONNECT(state, userData) {
      state.users.splice(
        state.users.findIndex((user) => user.id === userData.userId),
        1
      );
      state.user_locations.splice(
        state.user_locations.findIndex((loc) => loc.userId === userData.userId),
        1
      );
    },
    // SOCKET_USER_LOCATION_UPDATE(state, userData) {
    //   //console.log(`SOCKET_USER_LOCATION_UPDATE: ${JSON.stringify(userData, null, 2)}`);
    //   state.user_locations.splice(
    //     state.user_locations.findIndex((loc) => loc.userId === userData.userId),
    //     1,
    //     userData.location
    //   );
    // },
  },
  actions: {
    // socket_newJob({ dispatch, commit }, jobData) {
    //   console.log('new job:');
    //   console.log(JSON.stringify(jobData, null, 2));
    //   //commit('SOCKET_NEW_JOB', jobData);
    // }
  },
};

export default new Vuex.Store({
  modules: {
    //jobs,
    users,
  },
});
