<template>
  <div class="home centeredContainer">
    <!-- <CmpJobBoard v-if="$root.loggedIn"> </CmpJobBoard> -->
      <v-card dark color="primary" class="pa-4" style="border-radius:40px;">
      <div class="text-center text-h2 mt-2 mb-n4"><span class="">Welcome to Lavendaze!</span></div>
        <div class="text-h6 text-center mb-6  mx-auto" style="max-width:804px;">
           <br>
We are a family run business with an eternal love for bees, herbs & all natural products.
Our goal is to promote healthy living through our unique products that aim to Soothe your Body, Renew your Mind & Uplift your Soul. 
Thank you for visiting our website. Your support is forever appreciated and we look forward to connecting with you!
        </div>
        <v-img contain  :src="slides[0]" max-height="900px" style=""></v-img>

        <!-- <h1 class="text-center mb-2">𝕊𝕠𝕠𝕥𝕙𝕖 𝕋𝕙𝕖 𝔹𝕠𝕕𝕪 • ℝ𝕖𝕟𝕖𝕨 𝕋𝕙𝕖 𝕄𝕚𝕟𝕕 • 𝕌𝕡𝕝𝕚𝕗𝕥 𝕋𝕙𝕖 𝕊𝕠𝕦𝕝</h1> -->
        <!-- <h3 class="text-center mb-4">𝙰𝚕𝚕-𝙽𝚊𝚝𝚞𝚛𝚊𝚕, 𝙻𝚘𝚌𝚊𝚕𝚕𝚢 𝚂𝚘𝚞𝚛𝚌𝚎𝚍 & 𝙷𝚊𝚗𝚍𝚖𝚊𝚍𝚎 𝚆𝚒𝚝𝚑 𝙻𝚘𝚟𝚎. <v-icon>mdi-heart</v-icon><v-icon>mdi-beehive-outline</v-icon></h3> -->
        <h2 class="text-center mb-6 mt-6">We are eternally grateful for your love & support on our small business journey. <v-icon color="secondary">mdi-heart</v-icon> </h2>
        


        

        
        <div class="d-flex justify-center mt-4">
          <v-btn x-large color="secondary" @click="$router.push('/shop/page/1')">Shop Now!</v-btn>
        </div>

      </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import CmpJobBoard from '@/components/CmpJobBoard.vue'

export default {
  name: "Home",
  data(){
    return {
      slides: [
        "https://i.ibb.co/xXHdPFW/home-page.webp",
        ]
    }
  },
  components: {
     CmpJobBoard
  },
};
</script>
