import axios from "axios";

export default {
  readCurrent(callBack) {
    axios
      .get("/sitesettings/readCurrent")
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },

  create(sitesettings, callBack) {
    axios
      .post("/sitesettings/create", sitesettings)
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },

  update(sitesettings, callBack) {
    axios
      .put("/sitesettings/update", sitesettings)
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },

  delete(id, callBack) {
    axios
      .delete("/sitesettings/delete", {data: {id: id}})
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },

  publish(id, published, callBack) {
    axios
      .put("/sitesettings/publish", {id: id, published: published})
      .then((response) => {
        if (response.data) {
          callBack(response.data);
        } else {
          callBack({success: false, error: new Error("no data obj")});
        }
      })
      .catch((error) => {
        callBack({success: false, error: error});
      });
  },
};
