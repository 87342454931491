<template>
  <div>
  <CmpLogin ref="cmpLogin"></CmpLogin>   
  <v-app-bar
      dark
      app
      :src="BannerImage"
      absolute
      background-color="primary"
  >

    <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          style="background-repeat: repeat-y;"

        ></v-img>
    </template>

    <!-- https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg https://images1.westend61.de/0000736535pw/france-provence-lavender-fields-EPF000126.jpg -->
    
    <!-- <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(255,255,255,.7), rgba(128,208,199,.3)"
      ></v-img>
    </template> -->

    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

    <div class="lavenTitle">Lavendaze</div>

    <v-spacer></v-spacer>
    <!--
    <v-btn icon>
      <v-icon style="color:#593277;">mdi-heart</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon style="color:#593277;">mdi-dots-vertical</v-icon>
    </v-btn> -->

    <template v-slot:extension>
      <div v-if="$vuetify.breakpoint.width < 400" class="d-flex align-content-space-around">
        <v-btn class="pa-0" text to="/" >Home</v-btn>
        <v-btn class="pa-0" text :to="shopPage">Shop</v-btn>
        <v-btn class="pa-0" text :to="blogPage">Blog</v-btn>
        <v-btn class="pa-0" text to="/Social">Social</v-btn>
      </div>
      <v-tabs v-else grow active-class="active_tab" hide-slider v-model="active_tab">
        <v-tab to="/" ><v-icon>mdi-home</v-icon></v-tab>
        <v-tab :to="shopPage">Shop</v-tab>
        <v-tab :to="blogPage">Blog</v-tab>
        <v-tab to="/Social">Social</v-tab>
      </v-tabs>
    </template>

  </v-app-bar>

  <v-navigation-drawer
      v-model="drawer"
      dark
      app
      clipped
      temporary
      disable-route-watcher
      color="primary"
      style="z-index:11;"
    >
            <div v-if="$root.loggedIn">
          <v-list>
            <v-list-item link>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ $root.user.firstName }} {{ $root.user.lastName }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $root.user.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="ClickedLogout">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  <v-icon>mdi-logout</v-icon> Logout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list nav dense>
            <v-list-item-group
              active-class="deep-grey--text text--accent-4"
            >
              <v-list-item to="/" @click="drawer = false">
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item>

              <v-list-item to="/Account" @click="drawer = false">
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Account</v-list-item-title>
              </v-list-item>

              <v-list-item to="/CustomerMessages" @click="drawer = false">
                <v-list-item-icon>
                  <v-icon>mdi-forum</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Customer Messages</v-list-item-title>
              </v-list-item>

              <v-list-item to="/Users" @click="drawer = false">
                <v-list-item-icon>
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Live Users</v-list-item-title>
              </v-list-item>

              <v-list-item @click="LinkInstagram">
                <v-list-item-icon>
                  <v-icon>mdi-instagram</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Link Instagram</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="$root.settings">
                <v-switch
                  @change="$root.SettingsChanged"
                  prepend-icon="mdi-snowflake"
                  v-model="$root.settings.snowEffect"
                  label="Show Snow"
                ></v-switch>
              </v-list-item>

              <v-list-item>
                <v-switch
                  prepend-icon="mdi-monitor-eye"
                  v-model="$root.customerView"
                  label="Customer View"
                ></v-switch>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>

        <div v-else>
          <v-list>
            <v-list-item link @click="ClickedLogin">
              <v-list-item-content>
                <v-list-item-title class="text-h6"
                  ><v-icon>mdi-login</v-icon> Login
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
  </v-navigation-drawer>

      <!-- <v-navigation-drawer v-model="drawer" absolute temporary>

      </v-navigation-drawer> -->

  </div>
</template>

<style  scoped>
.lavenTitle{
  font-family: 'Brush Script MT', cursive;
  font-size: 4em;
  text-shadow:
    3px 3px 0 #000,
  -1px -1px 0 #000,  
  1px -1px 0 #000,
  -1px 1px 0 #000,
    1px 1px 0 #000;
}
.active_tab {
  background-color: rgba(137, 43, 226, 0.552);
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  
  font-size: large;
  font-weight: 900;
  text-shadow:
      3px 3px 0 #000,
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
      1px 1px 0 #000;
}
.v-tab:not(.v-tab--active) {
  color: rgb(255, 255, 255) !important;
  font-weight: 900;
  font-size: large;
     text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}
.mdi-home::before{
  color: white;
}
.theme--light.v-app-bar.v-toolbar.v-sheet{
  background-color: #593277;
}
.theme--dark.v-app-bar.v-toolbar.v-sheet{
    background-color: #593277;
}
</style>

<script >
import CmpLogin from "@/components/CmpLogin.vue";
import instagram_auth from "@/api/instagram_auth.js";

export default {
  data() {
    return {
      drawer : false,
      active_tab: 1,
      blogPage: "/blog/page/1",
      shopPage: "/shop/page/1",
    }
  },
  created(){
    
  },
  computed: {
    BannerImage() {
      if(this.$vuetify.breakpoint.width > 1186)
      {
        return "/lavender_banner2.jpg";
      }
      return "/lavender_banner.jpeg"
    }
  },
  methods: {
    ClickedContactUs() {
      this.drawer = false;
      this.$emit('show-contact-us');
    },
    ClickedLogin() {
      this.drawer = false;
      this.$refs.cmpLogin.Open();
    },
    ClickedLogout() {
      if (confirm("Are you sure you want to log out?")) {
        this.drawer = false;
        this.$root.Logout();
      }
    },
    LinkInstagram(){
      instagram_auth.get_url(resp=>{
        if(resp.success)
        {
           window.location.href = resp.url; // NOTE("Justin"): sending them to the auth popup
        }
      });
    }
  },
  watch:{
    $route(newVal, oldVal)
    {
      if(this.$route.name.includes("Blog"))
      {
        this.active_tab = this.blogPage;
      }
      else if(this.$route.name.includes("Shop"))
      {
        this.active_tab = this.shopPage;
      }
    }
  },
  components: {
    CmpLogin,
  },
}
</script>
