export default
  {
    email: '',
    password: '',
    required: value => !!value || "Required.",
    notNull: [value => (value != null) || "Required."],
    nameRules: [
      value => !!value || "Required.",
    ],
    emailRules: [
      value => !!value || "Required.",
      value => /.+@.+/.test(value) || "E-mail must be valid",
      value => value.length <= 255 || "Less then 255 chars"
    ],
    passwordRules: [
      value => !!value || "Required.",
      value => value != null && value.length >= 8 || "Min 8 characters",
      value => value != null && value.length <= 255 || "Less then 255 chars"
    ],
    phoneRules: [
      value => !!value || "Required.",
      value => value != null && value.length == 10 || "Need 10 characters. EX:5199021234",
      //value => value != null && value.length <= 14 || "Less then 14 chars"
    ],
    emailVerifyRules: [
      value => value === this.email || "Must match above email"
    ],
    passwordVerifyRules: [
      value => value === this.password || "Must match above password"
    ] 
}
